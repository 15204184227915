import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage1Avaleht from "./pages/Webpage1Avaleht";
import Webpage3Cookie_note from "./pages/Webpage3Cookie_note";
import Webpage4Page_not_found from "./pages/Webpage4Page_not_found";
import Webpage9Men_____vaated from "./pages/Webpage9Men_____vaated";
import Webpage11Menu_templates from "./pages/Webpage11Menu_templates";
import Webpage13Home from "./pages/Webpage13Home";

var baseURL = 'https://lilleorumtu.kassahaldus.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiYTUzMzFmMjI0MzZiMmUzNTZmMDdhNGYzYmRiNzhkNWYzNjYzNWRmN2Q1NmZhNmZhN2U2MTBkYTM1ZWI4YTY1YjdiNjQyYTNiNjI1ZjVhNTciLCJpYXQiOjE3MjgzMTAzMjguOTc2NzExLCJuYmYiOjE3MjgzMTAzMjguOTc2NzE1LCJleHAiOjE3MjgzOTY3MjguOTczMjI5LCJzdWIiOiI2NyIsInNjb3BlcyI6W119.Q8yHxgfnOvTS-vvuxRZHFv3VrZQKdbCVOaLb_MMyNivGpVsfwoio8swMi1HeJ0NbpF3IoRhADDyz2egbTGfjN7FgZ6gHVZdN-v2RZ8GUa1Mw6oGnLrRB9GO90FYvrWEdw-2JF_A5xwnM6iVvdXnfEDxawDvRavjgjyDqhfEhJnKenRWUAZ3hu3oklTP-oB5QxI6KOd7no5dOcnt21NX5HP2tG1mkpgsNhXL4q8wQImc4d-pBN2QOy6xtyp4XpJicjNXxYqA7GKMG5W6QlIZkOqYCdY1wsDFdE4eGt_GJNyKr5b-YXgUxYvJejTxdTeLYCQgkmmBAACpJkHjuqJB9FUZD2RHI8aii5sF4zKnJxBiE_uPD8yl0Z0owfZncXi_YmTbuZgLceltKzaa0botvFrKvMswhTQ2IS0gl95aaQ0EFkUYA3rLqt89jjJ_sG-1oma2QAjROQlgWRZo9-YKgktdPCn9oK_SJVfHuRtBrb_sMQwUCOoro6HCzvpYzQWuo-7AKl-LjT_zT4RrzZNsaJLBu7oAo_8VmNtJr5dVRwun41cCHH8CeqoB09J113vFhp3xT_gwwU_kMMMr65-ZffvOGQyywG4CCE8KkH2tOcgTyB6VZ6kWayTQ7gUKX9eAj5S9_naOuXLSzCHTi4DzSEs7PHzhAzhtgQhnD2wPCsow',
    'Access-api-key': "eyJpdiI6InlMVWZmb0hadlY3eTJ2azROL2VMU1E9PSIsInZhbHVlIjoiZ09nTVJZS29icnE4VFZqWXFXd2RyQT09IiwibWFjIjoiYzNhMGI3OGI4MmRiNzAzYzI4YTExYjYwM2JkMmVmYjQyNjZjYWE2YTBiODY2NTVjYmYxMzEyNWExNTdjNTFkYiIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6Ik9HZS9TZHZwVE1kdHFWK0M0dlVqY3c9PSIsInZhbHVlIjoiR2JOU1lzeTBWYlp5M1Vld245TFVNUT09IiwibWFjIjoiMjNhMTBlYjVkMDlhMGI4NGY3MjYzNDdiMTU2OGNiNjg1YjYzYjIwNDIzZWY4NjdiODNmNjI0ZDdhZGJkMGQ5NiIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: "GTM-WW9QW6"
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage1Avaleht />} exact />;
                                                                    <Route path="/en/cookie-note" element={<Webpage3Cookie_note />} exact />;
                                                                    <Route path="/en/*" element={<Webpage4Page_not_found />} exact />;
                                                <Route path="*" element={<Webpage4Page_not_found />} exact />;
                                                                    <Route path="/et/templates" element={<Webpage9Men_____vaated />} exact />;
                                                                    <Route path="/en/templates" element={<Webpage11Menu_templates />} exact />;
                                                                    <Route path="/en/" element={<Webpage13Home />} exact />;
                                                                
                                                            </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}